















import Vue from 'vue';
import 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import Component from 'vue-class-component';
import BudgetHeaderBip from '@/modules/budget/bip/budget-header-bip.vue';


// декоратор @Component указывает, что класс — это компонент Vue
@Component({
    name: 'bip-list',
    components: {
        'multiselect': Multiselect,
        BudgetHeaderBip
    }
})

export default class BipList extends Vue {
    private bipListtableFields =
        [
            {
                label: 'Код проекта',
                key: 'code'
            },
            {
                label: 'Наименование проекта',
                key: 'name_ru'
            },
            {
                label: 'Период реализации',
                key: 'period'
            },
            {
                label: 'Вид проекта',
                key: 'project_type'
            },
            {
                label: 'Населенный пункт',
                key: 'location'
            },
            {
                label: 'Источник финансирования (с разбивкой)',
                key: 'source'
            },
            {
                label: 'Общая стоимость (тыс. тенге)',
                key: 'totalCost'
            },
            {
                label: 'Стоимость без ПИР и ГЭ',
                key: 'costWithout'
            },
            {
                label: 'Финансирование до начала планового периода  (тыс. тенге)',
                key: 'beforePlan'
            },
            {
                label: 'Сумма на плановый период',
                key: 'years'
            },
            {
                label: 'Статус проекта',
                key: 'status'
            }
        ];

    private bipList: any[] = []

    private async loadBipList() {
        let response: any = [];
        response = await fetch('/api-py/bip-list');
        response = await response.json();
        this.bipList = response;
    }

    public created() {
        this.loadBipList()
    }


}
